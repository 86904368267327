<template>
    <span>
        <div class="row">

            <div class="col-12 col-sm-3 col-md-4 col-lg-4">

                <div class="row note-list">
                    <div v-for="(note, index) in notes" :key="index" class="col-12 col-md-12 note-section">
                        <AdmissionNote
                            :note="note" :index="index" @openModal="openModal"
                        />
                    </div>
                </div>
            </div>
                
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 medicine-seg">
                <div class="row rx-notes">
                    <div v-for="(note, index) in rxList" :key="index" class="col-12 col-md-12 note-section">

                        <RxNote
                            v-if="['investigation', 'rx'].includes(note.value)"
                            :note="note" :index="index"
                            @openModal="openModal"
                            :medicineNotes="medicineNotes"
                            from="doctorOrder"
                            @onClickToPrintRx="onClickToPrintRx"
                        />

                        <AddNewRxBtn 
                            v-else-if="note.value === 'rxBtn'"
                            :note="note" :index="index"
                            @addNewRxCard="addNewRxCard"
                        />

                        <AdmissionNote
                            :note="note" v-else
                            :index="index" @openModal="openModal"
                        />

                    </div>
                </div>
            </div>

            <div class="col-12 footer-section">
                <button type="button" @click="submit" class="btn btn-primary me-1 waves-effect waves-float waves-light">
                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <i class="fas fa-plus"></i>
                    Submit
                </button>
            </div>

        </div>

        <AddNoteModal
            v-if="store.state.isModalOpen"
            :note="singleNote"
        />

        <AddRxNoteModal
            v-if="store.state.isModalOpenTwo"
            :note="singleNote"
            :products="products"
        /> 

        <Loader v-if="loader"/>
    </span>
</template>

<script setup>
import AdmissionNote from '@/components/molecule/company/hospital/note/Note.vue';
import RxNote from '@/components/molecule/company/hospital/note/RxNote.vue';
import Loader from "@/components/atom/LoaderComponent";
import AddNoteModal from '@/components/molecule/company/hospital/note/AddNoteModal.vue';
import AddRxNoteModal from '@/components/molecule/company/hospital/note/AddRxNoteModal.vue';
import handleHospital from '@/services/modules/hospital'
import AddNewRxBtn from '@/components/molecule/company/hospital/ipd-station/components/AddNewRxBtn.vue';
import handleCompany from "@/services/modules/company";
import dischargeOrderHelper from '@/services/utils/pdf/dischargeOrderHelper';

import { inject, onMounted, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { head } from 'lodash';
import { json_string_parse, groupBy, getCurrentFormattedDateTime } from '@/services/utils/global'

const $router = useRouter();
const $route = useRoute();
const store = useStore();
const { fetchSingleDoctorOrder, storePrescription, updatePrescription } = handleHospital();
const { fetchCompanyInfo } = handleCompany();
const { generateRxPdf  } = dischargeOrderHelper();
const singleNote = ref(null);
const notes = reactive([])
const rxList = reactive([])
const medicineNotes = reactive([]);
const loading = ref(false);
const loader = ref(false);
const service = ref({});
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const initialRx = ref({});
const companyInfo = ref({});

const props = defineProps({
    prescriptionType: String,
    products: Array,
    admissionInfo: {
        type: Object,
        default: {}
    }
})

const onClickToPrintRx = async ({rxNote, index}) => {
    await generateRxPdf(companyInfo.value, props.admissionInfo, rxNote)
}

const submit = async () => {

    const formData = formateFormData();
    const doctorOrderId = service.value.doctor_order && service.value.doctor_order.id;
    const prescriptionAction = doctorOrderId ? updatePrescription : storePrescription

    loading.value = true
    await prescriptionAction(formData, doctorOrderId).then((res) => {
        loading.value = false
        if (!res.status) return showError(res.message);
        onClickToClose();
        return showSuccess(res.message);
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const formateFormData = () => {
    const {rx, investigation, other_notes} = formatNotes();
    const formData = {
        rx_notes: rx,
        investigations: investigation,
        other_notes: other_notes,
        contact_profile_id: service.value.contact_profile_id,
        service_general_id: service.value.id,
        service_resource_id: service.value.service_resource ? service.value.service_resource.id : null,
        company_id: $route.params.companyId,
        prescription_type: props.prescriptionType
    }
    return formData;
}

const formatNotes = () => {
    const noteObj = {
        rx: [],
        investigation: [],
        other_notes: [],
    };

    const allNotes = rxList.filter(item => item.selectedNotes.length);
    
    for(let i = 0; i < allNotes.length; i++){

        const note = allNotes[i];

        if(noteObj.hasOwnProperty(note.value)){
            const formattedSelectedNotes = note.selectedNotes.map(item => {
                item.group_index = i;
                item.order_date = note.date;
                item.service_resource_id = note.service_resource ? note.service_resource.id : null;
                return item;
            })

            if(note.newNotesByDoctor.length){
                noteObj.other_notes = noteObj.other_notes.concat(setMedicineData(note.newNotesByDoctor));
            }

            noteObj[note.value] = noteObj[note.value].concat(formattedSelectedNotes);

            continue;
        }

        noteObj['other_notes'].push({
            id: note.id ? note.id : null,
            type : note.value,
            note : JSON.stringify(note.selectedNotes),
            new_notes: note.newNotesByDoctor
        });
    }

    return noteObj;
} 

const setMedicineData = (notes) => {
    return notes.map(note => {
        const newNote = [ note.name ];
        return {
            id: note.id,
            type : note.type,
            note : JSON.stringify(newNote),
            new_notes: newNote
        }
    })
}

const openModal = ({note, index}) => {
    singleNote.value = null;
    singleNote.value = {
        index: index,
        name: note.name,
        value: note.value,
        selectedNotes: note.selectedNotes,
        preAddedNotes: json_string_parse(note.preAddedNotes),
        noteMasters: note.noteMasters,
        newNotesByDoctor: note.newNotesByDoctor
    }

    if(['rx', 'investigation'].includes(note.value)){
        store.state.isModalOpenTwo = true;
        return;
    }
    
    store.state.isModalOpen = true;
}

const getQuery = () => {
    let query = `?company_id=${$route.params.companyId}`;
    return query;
}

const setDoctorOrderData = () => {

    if(service.value.notes.length){
        setNoteData(service.value.notes, notes, true);
    }
    
    const doctorOrder = service.value.doctor_order;

    setAddNewBtnRow(doctorOrder);

    if(!doctorOrder) return;
    
    if(doctorOrder.investigation_orders.length){
        setInvestigationOrderData(doctorOrder.investigation_orders, 'investigation');
    }

    if(doctorOrder.medicine_orders.length){
        setMedicineOrderData(doctorOrder.medicine_orders);
    }
    
    if(doctorOrder.notes.length){
        setNoteData(doctorOrder.notes, rxList);
    }
}

const setNoteData = (other_notes, prevNotes, isHideAddBtn = false) => {
    for(let noteItem of other_notes){

        let prevNote = prevNotes.find(item => item.value === noteItem.type);
        if(!prevNote) continue;
        
        prevNote.id = noteItem.id;
        prevNote.selectedNotes.push(...noteItem.note);
    }
}

const onClickToClose = () => {
    $router.push({name: 'ipd-station',
        params: $route.params,
        query: $route.query
    })
}

const setMedicineOrderData = (medicineRx) => {
    
    let formattedMedicineRx = formatMedicineRx(medicineRx);
    formattedMedicineRx = groupBy(formattedMedicineRx, 'group_index');

    const prevRxCardIndex = rxList.findIndex(item => item.value === 'rx');

    if(prevRxCardIndex !== -1){
        initialRx.value = rxList[prevRxCardIndex];
        rxList.splice(prevRxCardIndex, 1);
    }

    const newRxList = [];

    for(const key in formattedMedicineRx){

        const firstHead = head(formattedMedicineRx[key])
        const date = firstHead.order_date ? firstHead.order_date : firstHead.created_at;

        newRxList.push(preparedRxCardData('Rx', 'rx', formattedMedicineRx[key], date, true, firstHead.service_resource_id));
    }

    newRxList.push(getNoteBody('New Rx', 'rxBtn'))

    rxList.unshift(...newRxList);
}

const addNewRxCard = ({note, index}) => {
    if(rxList[index-1].selectedNotes.length < 1) return;
    rxList.splice(index, 0, preparedRxCardData('Rx', 'rx'));
}

const preparedRxCardData = (name = 'Rx', value = 'rx', medicines = [], date = getCurrentFormattedDateTime(), isEdited = false, serviceResource = null) => {
    return {
        name: name,
        value: value,
        date: date,
        service_resource: serviceResource,
        isHideAddBtn: isEdited,
        isEdited: isEdited,
        preAddedNotes: initialRx.value.preAddedNotes,
        selectedNotes: medicines,
        newNotesByDoctor: [],
        noteMasters: []
    }
}

const formatMedicineRx = (medicineRx) => {
    return medicineRx.map((medicine) => {
        return {
            id: medicine.id,
            product_id: medicine.product_id,
            service_resource_id: medicine.service_resource,
            name: medicine.product.name,
            remarks: medicine.remarks,
            isSelected: true,
            isEdited: true,
            dose: medicine.dose,
            unit: medicine.unit,
            duration: medicine.duration,
            madication_status: medicine.madication_status,
            group_index: medicine.group_index,
            order_date: medicine.order_date,
            created_at: medicine.created_at,
            status: medicine.status
        }
    })
}

const setInvestigationOrderData = (medicineRx, noteType) => {

    let prevNote = rxList.find(item => item.value === noteType);
    if(!prevNote) return;

    for(let note of medicineRx){

        const noteObj = {
            id: note.id,
            product_id: note.product_id,
            name: note.product.name,
            remarks: note.remarks,
            isSelected: true,
            status: 'active'
        };

        prevNote.selectedNotes.push(noteObj)
    }
}

const setAddNewBtnRow = (doctorOrder) => {

    if(doctorOrder && doctorOrder.medicine_orders.length) return;

    const rxIndex = rxList.findIndex(item => item.value === 'rx');
    if(rxIndex < 0) return;

    initialRx.value = rxList[rxIndex];

    rxList[rxIndex].date = getCurrentFormattedDateTime();
    rxList[rxIndex].service_resource = null;

    rxList.splice((rxIndex + 1), 0, getNoteBody('New Rx', 'rxBtn'));
}

const getNoteBody = (key = 'Rx', value = 'rx') => {
    return {
        name: key,
        value: value,
        isHideAddBtn: false,
        preAddedNotes: [],
        selectedNotes: [],
        newNotesByDoctor: [],
        noteMasters: []
    }
}

const fetchInitialData = async () => {
    const serviceId = $route.params.serviceId;
    const query = getQuery();
    const prescriptionType = props.prescriptionType;

    loader.value = true;
    await Promise.all([
        fetchSingleDoctorOrder(query, serviceId, prescriptionType).then((res) => {
            if (!res.status) return showError(res.message)
            notes.push(...res.data.prescriptionLeftSideNotes)
            rxList.push(...res.data.prescriptionRightSideNotes)
            medicineNotes.push(...res.data.rxNotes)
            service.value = res.data.service;
        }),
    ]).then(() => {

        setDoctorOrderData();
        getCompnayInfo();

        loader.value = false;
        
    }).catch((err) => {
        loader.value = false
    });
}

const getCompnayInfo = async () => {
    await fetchCompanyInfo($route.params.companyId)
        .then((res) => {
            if(res.data) companyInfo.value = res.data;
        })
}

onMounted(() => {
    fetchInitialData();
})
</script>

<style scoped>
.note-section {
    margin-top: 2%;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    width: 96%;
    margin-left: 2%;
}

.footer-section{
    text-align: center;
    padding: 3% 0;
}

.note-list{
    margin-left: 0%;
    margin-top: 2%;
}
.medicine-seg{
    border-left: 1px solid black;
    min-height: 800px;
}
.rx-notes{
    padding-right: 1rem;
}
</style>