import dischargeOrder from '@/services/utils/pdf/dischargeOrder';
import pdfRxMedicineOrder from '@/services/utils/pdf/pdfRxMedicineOrder';
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import useDate from '@/services/utils/day';
import { groupBy } from '@/services/utils/global';
import { head } from 'lodash';
import { useRoute } from 'vue-router';


const dischargeOrderHelper = () => {
    const { exportToPDF } = dischargeOrder();
    const { exportRxPDF } = pdfRxMedicineOrder();
    const { fetchBarcode } = handleBarcode();
    const store = useStore();
    const route = useRoute();
    const { formatDate } = useDate();
    const userName = store.state.user.user.name;
    var qrcode = '', barcodePatient = '', barcodeAdmission = '';

    const generateRxPdf = async (company, admissionInfo, rxNote) => {
        let qrcodeData = JSON.stringify({
            ref_no: admissionInfo.admission_no,
            ref_date: admissionInfo.admission_date,
            created_by: userName,
            created_date_time: formatDate(new Date())
        })

        let barCodeQuery = `?code=${admissionInfo.patient.serial_no}&qrcode=${qrcodeData}`;
        let barCodeAdmissionQuery = `?code=${admissionInfo.admission_no}`;

        await Promise.all([
            fetchBarcode(barCodeQuery).then( (res) => {
                barcodePatient = res.barcode
                qrcode = res.qrcode;
            }),
            fetchBarcode(barCodeAdmissionQuery).then( (res) => {
                barcodeAdmission = res.barcode;
            })
        ]).then(() => {
            exportRxPDF(company, admissionInfo, qrcode, userName, barcodePatient, barcodeAdmission, rxNote);
        }).catch((e) => {
            console.log(e);
        })
    }

    const generatePdf = (company, admissionInfo, dischargeNotes, formData, notesOrder, dischargeModes, treatedByDoctors, serviceDoctorOrder = null) => {
        let qrcodeData = JSON.stringify({
            ref_no: admissionInfo.admission_no,
            ref_date: admissionInfo.admission_date,
            created_by: userName,
            created_date_time: formatDate(new Date())
        })

        var barCodeQuery = `?code=${admissionInfo.patient.serial_no}&qrcode=${qrcodeData}`;
        var barCodeAdmissionQuery = `?code=${admissionInfo.admission_no}`;

        Promise.all([
            fetchBarcode(barCodeQuery).then( (res) => {
                barcodePatient = res.barcode
                qrcode = res.qrcode;
            }),
            fetchBarcode(barCodeAdmissionQuery).then( (res) => {
                barcodeAdmission = res.barcode;
            })
        ]).then(() => {
            exportToPDF(company, admissionInfo, dischargeNotes, formData, qrcode, userName, barcodePatient, barcodeAdmission, notesOrder, dischargeModes, treatedByDoctors, serviceDoctorOrder)
        }).catch((e) => {
            console.log(e);
        })
    }

    const formatInvestigations = (investigationData, investigationHeads) => {

        const data = [];
 
        if(! investigationData.length || ! investigationHeads.length) return data;
        
        const investigations = groupBy(investigationData, 'id');

        for(const key in investigations){
            
            if(! investigationHeads.includes(Number(key))) continue;
            
            let investigation = {
                reportName: head(investigations[key]).name,
                latestResult: {},
                oldResult: {},
                otherResults: [],
            };
            
            for(const item of investigations[key]){
    
                if(item.type === 'investigation_history'){
                    investigation.oldResult = item;
                    continue;
                }

                investigation.otherResults.push({
                    id: item.investigation_general_id,
                    result: item.result
                })
    
                investigation.latestResult = item;
            }
    
            data.push(investigation);
        }
        return data;
    }

    return{
        generateRxPdf,
        generatePdf,
        formatInvestigations
    }
}

export default dischargeOrderHelper;