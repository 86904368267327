<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    						
                    <tr>
                        <th>Photo</th>
                        <th class="patient-details">Baby name</th>
                        <th>Gender</th>
                        <th>Weight</th>
                        <th>APGAR Score</th>
                        <!-- <th>Birth Certificate</th> -->
                        <th class="action-th">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">

                        <td>
                            <img :src=item.photo alt="avatar" class="profile-pic">
                        </td>

                        <td> 
                            <div class="col">
                                {{ item.name }}
                            </div>
                        </td>

                        <td>
                            {{ toCapitalizeFirst(item.gender) }}
                        </td>

                        <td>
                            {{ item.weight }}
                        </td>

                        <td>
                            {{ item.apgar_score }}
                        </td>

                        <!-- <td>
                            <div class="action">
                                <button class="btn btn-outline-primary btn-sm">
                                    <i class="fas fa-print"></i>
                                    Birth Certificate
                                </button>
                            </div>
                        </td> -->

                        <td>
                            <div class="action">
                                <button @click="$emit('onEditBabyNote', item)" class="btn btn-primary btn-sm print-btn">
                                    <i class="fas fa-pen"></i>
                                </button>
                              <button :disabled="loading" @click="deleteBabyNote(item)" class="btn btn-outline-danger btn-sm print-btn">
                                <i class="fas fa-trash"></i>
                              </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { toCapitalizeFirst } from '@/services/utils/global';
import {inject, ref} from "vue";
import handleHospital from '@/services/modules/hospital'

const $route = useRoute();
const $router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false);
const { removeBabyNote } = handleHospital();

const props = defineProps({
    tableItems: {
        type: Array,
        default: []
    },
    onEditBabyNote: Function,
    onDelete: {
      type: Function,
      default: () => {}
    }
})

const emit = defineEmits(['onEditBabyNote','onDelete']);

const deleteBabyNote = async (item) => {

  if(!confirm('Are you sure you want to delete this Baby Note?')) return;

  const query = '?company_id=' + $route.params.companyId;

  loading.value = true

  try {
    let res = await removeBabyNote(query, item.id);
    if (!res.status) {
      showError(res.message);
      return;
    }
    emit('onDelete');
    showSuccess(res.message)
  } catch (err) {
    console.log(err,"err")
    if (!err.response) {
      showError('Something is wrong. Check your connectivity!!');
      return;
    }
    showError(err.response.message)
  } finally {
    loading.value = false
  }
}

    
</script>

<style scoped>

.admission-no{
    width: 15%;
}
.patient-details{
    width: 25%;
}
.print-btn{
    margin-right: 5px;
}
.action-th{
    width: 15%;
    text-align: center;
}
.action{
    text-align: center;
}

.profile-pic{
    width: 65px;
}
</style>