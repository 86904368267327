<template>
    <div class="profile-header-nav" data-v-896bdbcc="">

    <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
        <button class="btn btn-icon navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-justify font-medium-5">
                <line x1="21" y1="10" x2="3" y2="10"></line>
                <line x1="21" y1="6" x2="3" y2="6"></line>
                <line x1="21" y1="14" x2="3" y2="14"></line>
                <line x1="21" y1="18" x2="3" y2="18"></line>
            </svg>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="profile-tabs d-flex justify-content-end flex-wrap mt-1 mt-md-0">
                <ul class="nav nav-pills mb-0">

                    <li 
                        v-for="(tab, index) in tabs" :key="tab.slug"
                        :tab="tab" :index="index"
                        class="nav-item"
                    >
                        <a 
                            :href="`#${tab.slug}`" class="nav-link"
                            :class="activeTab === tab.slug && 'fw-bold active'"
                            @click="onHandleClick(tab)"
                        >
                            <span class="d-md-block">{{ tab.title }}</span>
                        </a>
                    </li>

                </ul>
            </div>
        </div>

    </nav>

    </div>
</template>

<script setup>
    const props = defineProps(['tabs', 'activeTab', 'setActiveTab'])
    const emits = defineEmits(['setActiveTab']);

    const onHandleClick = (tab) => {
        emits('setActiveTab', tab);
    }
    
</script>

<style scoped>
.nav-link{
    padding: 8px 6px;
}
.navbar {
    padding-right: 1%;
}
.nav-pills .nav-link, .nav-tabs .nav-link {
    justify-content: start !important;
}
.nav-link {
    border: 1px solid #7367f0;
    margin-left: 5px;
}
@media only screen and (min-device-width: 800px) and (max-device-width: 990px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .nav-pills .nav-link {
      padding: 0.786rem 0.5rem !important;
  }
}

@media (min-width: 768px){
    .profile-header-nav .profile-tabs {
        margin-left: 0 !important;
    }
}

</style>