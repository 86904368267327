<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal-lg" ref="admissionModalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center px-1 my-1 align-item-center">
                            <h1 class="mb-0">Add Medicine Note</h1>
                            <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0">
                
                <div class="modal-body">

                    <div class="row gy-2 px-2">

                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <button 
                                        v-for="tab in noteObj.medicineNotes" :key="tab.value"
                                        type="button" :class="noteObj.currentTab === tab.value ? 'btn-primary' : 'btn-outline-primary'"
                                        class="btn btn-sm" @click="onTabChange(tab)"
                                    >
                                        {{ tab.name }}
                                    </button>
                                </div>
                                <div class="card-body modal-card" v-if="currentNoteObj">
                                    <button
                                        v-for="(preNote, index) in currentNoteObj.noteMasters" :key="index" 
                                        @click="onSelectNote(preNote)"
                                        class="btn cancel-subscription mt-1 waves-effect round med-title" 
                                        :class="selectedNote === preNote.name ? 'btn-primary' : 'btn-outline-primary'">
                                        <i class="fas fa-check"></i>
                                        {{ preNote.name }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    Or Add new
                                </div>
                                <div class="card-body row">
                                    <div class="col-12 mb-2">
                                        <textarea v-model.trim="newValue" class="form-control"></textarea>
                                    </div>
                                    <div class="col-12 text-center">
                                        <SubmitCancelBtn
                                            @onSubmit="submit"
                                            :loading="loading"
                                            submitTxt="Add new"
                                            :disabled="!Boolean(newValue)"
                                            :isHideCancelBtn="true"
                                        >
                                            <template #submit-btn-icon>
                                                <i class="fas fa-plus"></i>
                                            </template>
                                        </SubmitCancelBtn>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, onMounted, ref } from "vue";
import { useStore } from "vuex";
import SubmitCancelBtn from '@/components/atom/SubmitCancelBtn.vue';

const props = defineProps({
    noteObj: Object,
    onHandleMedNote: Function,
    onCloseModal: Function,
})

const emit = defineEmits(['onHandleMedNote', 'onCloseModal']);
const store = useStore();
const currentNoteObj = ref(null);
const selectedNote = ref('');
const newValue = ref('');
const loading = ref(false);
const showError = inject('showError');

const closeModal = () => {
    emit('onCloseModal', false)
}

const onSelectNote = (note, newNote = '') => {
    selectedNote.value = note.name;
    emit('onHandleMedNote', {note, newNote});
}

const submit = () => {
    if(currentNoteObj.value.noteMasters.some(item => item.name === newValue.value)){
        return showError('Note already exists');
    }
    const newNoteObj = {name: newValue.value, type: props.noteObj.currentTab};
    newValue.value = '';
    currentNoteObj.value.noteMasters.push(newNoteObj);
    onSelectNote(newNoteObj, newNoteObj);
}

const onTabChange = (tab) => {
    selectedNote.value = props.noteObj.selectedNote[tab.value];
    props.noteObj.currentTab = tab.value;
    setCurrentNote(tab.value);
}

const setCurrentNote = (value) => {
    currentNoteObj.value = props.noteObj.medicineNotes.find(item => item.value === value);
}

onMounted(() => {
    const currentTab = props.noteObj.currentTab === 'investigation_remarks' ? 'remarks' : props.noteObj.currentTab;
    setCurrentNote(props.noteObj.currentTab);
    selectedNote.value = props.noteObj.selectedNote[currentTab];
})
</script>

<style scoped>
.modal-body{
    min-height: 400px;
}
.card-header {
    padding-top: 0%;
}
.card-header button {
    color: #7367f0;
    padding-left: 8%;
    padding-right: 8%;
    font-weight: bold;
}
.bg {
    background: aliceblue !important;
}
.note-search{
    width: 50%;
}
.med-title{
    padding: 0.486rem 1.5rem;
}
.modal-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
</style>