<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                :showAddNew="true"
                title="OT Order"
                btnTitle="Add new"
                @onClickAddNewButton="onClickAddNew"
            />
        </div>

        <div class="px-2">
            <div class="row justify-content-center">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                    <label for="colFormLabel" class="col-form-label">Search</label>
                    <div class="input-group input-group-merge w-100">

                        <span class="input-group-text">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search text-muted">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                        </span>

                        <input 
                            type="text" class="form-control"
                            placeholder="Search/Scan Barcode - by Patient ID" aria-label="Search..."
                            aria-describedby="chat-search"
                        />

                    </div>
                </div>
                <div class="col-2 d-flex align-items-end mt-2">
                    <button
                        style="min-width: 64px;"
                        class="btn btn-primary btn-block waves-effect waves-float waves-light"
                    >Go
                    </button>
                </div>
            </div>
        </div>

        <div class="col-12 px-2 mt-2">
            <ListTable 
                :tableItems="tableItems"
                @onEditOTOrder="onEditOTOrder"
                @onDelete="onSumit"
            />
        </div>

        <div class="px-2 position-absolute bottom-0">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>

        <OTOrderModal
            v-if="store.state.isModalOpen"
            :incessions="incessions"
            :anesthesias="anesthesias"
            :contactProfileId="admissionInfo.contact_profile_id"
            :editData="editData"
            @onSumit="onSumit"
        />
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import ListTable from '@/components/molecule/company/hospital/ipd-station/OTOrderTable.vue';
import OTOrderModal from '@/components/molecule/company/hospital/ipd-station/OTOrderModal.vue';
import handleHospital from '@/services/modules/hospital'
import { inject, onMounted, reactive, ref } from 'vue';
import { generateUUID } from '@/services/utils/global'
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'

const props = defineProps({
    admissionInfo: Object,
})

const { fetchOtOrderTypes, fetchOtOrderList, fetchSingleOtOrder } = handleHospital()
const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const incessions = ref([]);
const anesthesias = ref([]);
const tableItems = reactive([]);
const params = reactive({
    searchTxt: '',
    offset: 10
});
const editData = ref(null);

const onClickAddNew = () => {
    editData.value = null;
    store.state.isModalOpen = true;
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const onSumit = () => {
    fetchOrders();
}

const onEditOTOrder = (item) => {
    const query = getQuery();
    fetchSingleOtOrder(query, item.id).then(({data}) => {
        if(data){
            editData.value = data;
            store.state.isModalOpen = true;
        }
    })
}

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}
async function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    router.push({path: route.path, query: routeQuery})
    await getRegistrationList();
}

const fetchTypes = () => {
    let query = `?company_id=${route.params.companyId}`;
    fetchOtOrderTypes(query).then(({data}) => {
        if(!data) return;
        const { incessionTypes, anestesiaTypes } = data;
        incessions.value = incessionTypes;
        anesthesias.value = anestesiaTypes;
    })
}

const fetchOrders = () => {
    const query = getQuery();
    loading.value = true

  fetchOtOrderList(query).then(res => {
        loading.value = false
        tableItems.length = 0;
        if (!res.status){
            store.commit("resetPagination")
            return
        }
        tableItems.push(...res.data.data);
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page
    if(params.searchTxt) query += `&searchTxt=${params.searchTxt}`;
    query += '&offset=' + params.offset
    query += '&ipd_register_id=' + route.query.admissionId;
    return query;
}

onMounted(async () => {
    await Promise.all([
        fetchTypes(),
        fetchOrders()
    ]);
})
</script>

<style scoped>

</style>