<template>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
        <DischargeNote>
            <table>
                <tr>
                    <td class="table-column">Gender</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ startCase(note.gender) }}</strong>
                    </td>
                </tr>
                <tr>
                    <td class="table-column">Weight</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ note.weight}}</strong>
                    </td>
                </tr>
                <tr>
                    <td class="table-column">APGAR Score</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ note.apgar_score }}</strong>
                    </td>
                </tr>
                <tr>
                    <td class="table-column">Special Note</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ convertArraytoString(note.notes) }}</strong>
                    </td>
                </tr>
            </table>
        </DischargeNote>
    </div>

</template>

<script setup>
import DischargeNote from '@/components/molecule/company/hospital/ipd-station/DischargeNote.vue';
import { joinArrayOfString } from '@/services/utils/global';
import { startCase } from 'lodash';

const props = defineProps({
    note: Object

})

const convertArraytoString = (notes) => {
    const note = notes.map(obj => obj.note).flat();
    return joinArrayOfString(note);
}
</script>