<template>
        
    <div class="row">

        <div class="col-12 col-sm-5 col-md-5 col-lg-5">
            <table>

                <tr v-for="item in leftSideData" :key="item.key">
                    <td class="table-column" :style="`width: ${leftColumnWidth}`">{{ item.key }}</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ item.value }}</strong>
                    </td>
                </tr>

            </table>
        </div>

        <div class="col"></div>

        <div class="col-12 col-sm-5 col-md-5 col-lg-5">
            <table>
                <tr v-for="item in rightSideData" :key="item.key">
                    <td class="table-column" :style="`width: ${rightColumnWidth}`">{{ item.key }}</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <strong>{{ item.value }}</strong>
                    </td>
                </tr>

                <tr>
                    <td class="table-column" :style="`width: ${rightColumnWidth}`">Discharge date</td>
                    <td class="table-divider"></td>
                    <td class="table-row"> 
                        <div class="input-group input-group-merge w-100">
                            <input
                                v-model="newDate"
                                name="datetime"
                                type="datetime-local"
                                class="form-control"
                                @change="onDataChange"
                            />
                        </div>
                    </td>
                </tr>
            </table>
        </div>

    </div>

</template>

<script setup>
import { onMounted, ref, watch, watchEffect } from "vue";

const props = defineProps({
  leftSideData: {
    type: Array,
    default: [],
  },
  rightSideData: {
    type: Array,
    default: [],
  },
  leftColumnWidth: {
    type: String,
    default: '30%'
  },
  rightColumnWidth: {
    type: String,
    default: '30%'
  },
  dischargeDate: {
    type: String,
  }
})
const newDate = ref('');
const emit = defineEmits(['update:dischargeDate'])

const onDataChange = () => {
    emit('update:dischargeDate', newDate.value)
}

watchEffect(() => {
    newDate.value = props.dischargeDate;
});
</script>

<style>
.table-divider{
    padding: 3px 10px;
}
.table-divider:after {
    content: ":";
}
</style>