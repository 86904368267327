<template>
    <div>
        <div class="card mb-1">
            <div class="bg-blue-light p-1">
                <TitleButton
                    :showBtn="true"
                    :showAddNew="false"
                    @onClickCloseButton="onClickToClose"
                    title="IPD Doctor's Order"
                />
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <RxNav
                        :tabs="tabs"
                        :activeTab="activeTab"
                        @setActiveTab="setActiveTab"
                    />
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 patient-details">
                    <div class="row" v-if="admissionInfo && admissionInfo.patient">
                        <div class="col-3 col-md-3">
                            <div class="name-seg">
                                <b>Name: </b>
                                <b>{{ admissionInfo.patient.full_name }}</b>
                            </div>
                        </div>
                        <div class="col-3 col-md-3">
                            <div class="name-seg">
                                <b>Age: </b>
                                <b>{{ getAge(admissionInfo.patient.birthday) }} years</b>
                            </div>
                        </div>
                        <div class="col-3 col-md-3">
                            <div class="name-seg">
                                <b>Date: </b>
                                <b>{{ formatDateToDdMmYy(admissionInfo.admission_date) }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">

            <component
                :is="components[activeComponent]"
                :currentTab="activeTab"
                v-if="activeTab"
                :admissionInfo="admissionInfo"
            />

        </div>
    </div>

</template>

<script setup>

    import TitleButton from '@/components/atom/TitleButton';
    import RxNav from '@/components/molecule/company/hospital/ipd-station/RxNav.vue';
    import IPDGeneralOrder from '@/components/molecule/company/hospital/ipd-station/Tabs/IPDGeneralOrder.vue';
    import PreOperative from '@/components/molecule/company/hospital/ipd-station/Tabs/PreOperative.vue';
    import PostOperative from '@/components/molecule/company/hospital/ipd-station/Tabs/PostOperative.vue';
    // import InvestigationOrder from '@/components/molecule/company/hospital/ipd-station/Tabs/InvestigationOrder.vue';
    import OTOrder from '@/components/molecule/company/hospital/ipd-station/Tabs/OTOrder.vue';
    import BabyNote from '@/components/molecule/company/hospital/ipd-station/Tabs/BabyNote.vue';
    import DischargeOrder from '@/components/molecule/company/hospital/ipd-station/Tabs/DischargeOrder.vue';
    import handleHospital from '@/services/modules/hospital'
    import handlePurchase from '@/services/modules/purchase';

    import { onMounted, reactive, ref } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { dateOfBirthConvertToAge, formatDateToDdMmYy } from '@/services/utils/global'

    const $router = useRouter();
    const $route = useRoute();
    const { fetchSingleNote } = handleHospital()

    const activeTab = ref('')
    const activeComponent = ref('IPDGeneralOrder')
    const admissionInfo = ref(null);

    const components = {
        IPDGeneralOrder,
        PreOperative,
        PostOperative,
        // InvestigationOrder,
        OTOrder,
        BabyNote,
        DischargeOrder,
    };

    const tabs = reactive([
        {slug: 'general_order', title: 'IPD General Order', component: 'IPDGeneralOrder' },
        {slug: 'pre_operative', title: 'Pre-operative', component: 'PreOperative' },
        {slug: 'post_operative', title: 'Post-operative', component: 'PostOperative' },
        // {slug: 'investigation_order', title: 'Investigation Order', component: 'InvestigationOrder'},
        {slug: 'ot_order', title: 'OT Order', component: 'OTOrder' },
        {slug: 'baby_note', title: 'Baby Note', component: 'BabyNote' },
        {slug: 'discharge_order', title: 'Discharge Order', component: 'DischargeOrder' },
    ])

    const getAge = (birthday) => {
        if(!birthday) return '';
        const {year} = dateOfBirthConvertToAge(birthday);
        return year;
    }

    const setActiveTab = (tab) => {
        activeTab.value = tab.slug;
        activeComponent.value = tab.component;
    };

    const onClickToClose = () => {
        $router.push({name: 'ipd-station',
            params: $route.params,
            query: $route.query
        })
    }

    const setCurrentTab = () => {
        let hashPath = $route.hash.substring(1);
        if(!hashPath) hashPath = tabs[0].slug;

        activeTab.value = hashPath;

        const tab = tabs.find(item => item.slug === hashPath);
        activeComponent.value = tab.component;
    }

    const getQuery = () => {
        let query = `?company_id=${$route.params.companyId}`;
        return query;
    }

    const getPatientDetails = async () => {
        const id = $route.query.admissionId || '';
        if(!id) return;
        const query = getQuery();

        await Promise.all([
            fetchSingleNote(query, id).then((res) => {
                if(res.status) admissionInfo.value = res.data
            }),
        ]);
    }

    onMounted( async () => {
        await getPatientDetails();
        setCurrentTab();
    })
</script>

<style scoped>
.patient-details{
    width: 94%;
    padding: 1% 0;
    margin-left: 30px;
}

</style>
