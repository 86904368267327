import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import handleHospital from "@/services/modules/hospital";
import useDate from '@/services/utils/day';

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const pdfRxCardPrinter = () => {

    const { formatDate } = useDate();
    const { calculateAge } = handleHospital();

    const exportRxPDF = (company, admissionInfo, qrcode, userName, barcodePatient, barcodeAdmission, rxNote) => {
        
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 25, 100, 25, 80 ],
            header: (currentPage, pageCount, pageSize) => (currentPage == 1) ? getHeader(company) : '',
            footer: (currentPage, pageCount, pageSize) => (currentPage == pageCount) ? getFooter(userName, qrcode) : '',
            content: getContent(doc, barcodePatient, barcodeAdmission, admissionInfo, rxNote),
            
            styles : {
                header: {
                    fontSize: 24,
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },

            info: {
                title: 'Medicine Slip'
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (doc, barcodePatient, barcodeAdmission, admissionInfo, rxNote) => {
        return [
            {
                text: 'Medicine Slip',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                svg: barcodePatient
                            },

                            {
                                text: `Patient Name: ${admissionInfo.patient.full_name}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `Patient ID: ${admissionInfo.patient.serial_no}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `${(admissionInfo.patient && admissionInfo.patient.birthday) ? 
                                        'Age: ' + calculateAge(admissionInfo.patient.birthday) : ''}` + 
                                        `${(admissionInfo.patient && admissionInfo.patient.gender) ? 
                                        ' | Sex: ' + admissionInfo.patient.gender : '' }`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `Patient Phone No: ${admissionInfo.patient.mobile_no}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `${admissionInfo.patient.full_address ? 'Patient Address: ' + admissionInfo.patient.full_address : ''}`,
                                alignment: 'left',
                                width: '50%'
                            },
                        ]
                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                svg: barcodeAdmission
                            },

                            {
                                text: `Admission No: ${admissionInfo.admission_no}`
                            },

                            {
                                text: `Admission Date: ${admissionInfo.formattedAdmissionData}`
                            },

                            {
                                text: `Discharge Date: `
                            }
                        ]
                    },
                ]
            },

            {
                canvas: [
                    {
                        type: 'line',
                        x1: 0, 
                        y1: 0, 
                        x2: 545,
                        y2: 0,
                    },
                ],
                margin: [0, 5]
            },

            {
                columns: [
                    {
                        width: '100%',
                        stack: getRxList(rxNote),
                    }
                ]
                
            }

        ]
    }


    const getRxList = (rxNote) => {

        const rxList = [
            getRxHeader(rxNote)
        ];

        for(let i = 0; i < rxNote.selectedNotes.length; i ++ ){

            const item = rxNote.selectedNotes[i];
            
            let name = getRxContent(item, i);
            let details = getRxDetails(item);

            rxList.push(name, details);
        }

        return rxList;
    }

    const getRxContent = (item, i) => {
        return {
            fontSize: 11,
            bold: true,
            margin: [0, 0, 0, 2],
            text: `${(i+1)}. ${item.name}`
        }
    }

    const getRxDetails = (item) => {
        return {
            fontSize: 11,
            bold: false,
            margin: [5, 2, 5, 5],
            text: `${item.dose ?? ''} \t ${item.unit ?? ''} \t ${item.remarks ?? ''} \t ${item.duration ?? ''}`
        }
    }

    const getRxHeader = (item) => {
        return {
            fontSize: 14,
            bold: true,
            margin: [0, 15, 0, 5],
            text: item.name
        }
    }

    const getHeader = (company) => {
        return {
            margin: [ 25, 25, 25, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60
                },

                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }

    const getFooter = (userName, qrcode) =>{
        return {
            margin: [ 25, 0 ],
            columns: [
                {
                    columns: [
                        {
                            svg: qrcode
                        },

                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    return {
        exportRxPDF
    }
}

export default pdfRxCardPrinter;
