<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ editData ? 'Edit' : 'Add' }} OT Order</h1>
                            <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0 mb-0">

                <div class="modal-body">

                    <div class="pb-2">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Name of operation</label>
                                <AsyncSelect
                                    placeholder="Select operation name"
                                    v-model="service_product"
                                    :api-service="fetchProducts"
                                    :additional-query="{ product_type: 'services' }"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Operation date</label>
                                <div class="input-group input-group-merge w-100">
                                    <input
                                        v-model="formData.operation_date"
                                        name="datetime"
                                        type="datetime-local"
                                        class="form-control"
                                    />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">Indication</label>
                                <textarea
                                    class="form-control"
                                    placeholder="Indication"
                                    :rows="2"
                                    v-model="formData.indication"
                                ></textarea>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Incession</label>
                                <v-select 
                                    label="name" :options="incessions"
                                    placeholder="Select incession"
                                    :reduce="(option) => option.value"
                                    v-model="formData.incession"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Anestesia</label>
                                <v-select 
                                    label="name" :options="anesthesias"
                                    placeholder="Select anestesia"
                                    :reduce="(option) => option.value"
                                    v-model="formData.anestesia"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">Special finding</label>
                                <textarea
                                    class="form-control"
                                    :rows="2"
                                    placeholder="Special finding"
                                    v-model="formData.special_finding"
                                ></textarea>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">Surgeons</label>
                                <AsyncSelect
                                    placeholder="Select surgeons"
                                    v-model="multipleFormValues.surgeon"
                                    :api-service="fetchServiceList"
                                    :isMultiple="true"
                                    :additional-query="{ resource_type: 'human_resources' }"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">Anestesiologists</label>
                                <AsyncSelect
                                    placeholder="Select anestesiologists"
                                    v-model="multipleFormValues.anestesiologist"
                                    :api-service="fetchServiceList"
                                    :isMultiple="true"
                                    :additional-query="{ resource_type: 'human_resources' }"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">Ast. surgeons</label>
                                <AsyncSelect
                                    placeholder="Select ast. surgeons"
                                    v-model="multipleFormValues.ass_surgeon"
                                    :api-service="fetchServiceList"
                                    :isMultiple="true"
                                    :additional-query="{ resource_type: 'human_resources' }"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">Nurses</label>
                                <AsyncSelect
                                    placeholder="Select nurses"
                                    v-model="multipleFormValues.nurse"
                                    :api-service="fetchServiceList"
                                    :isMultiple="true"
                                    :additional-query="{ resource_type: 'human_resources', user_role: 'nurse' }"
                                />
                            </div>

                            <div class="col-12 text-center">
                                <hr class="mt-2">
                                <button 
                                    :disabled="loading" @click="submit"
                                    class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light"
                                >
                                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    Submit
                                </button>
                                <button @click="closeModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                    Cancel
                                </button>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script setup>
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import { useAsyncDropdownHelper } from "@/services/utils/asyncDropdownHelper";
import handleHospital from '@/services/modules/hospital'
import handlePurchase from '@/services/modules/purchase';
import { getCurrentDateTime } from '@/services/utils/global.js'
import { inject, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const props = defineProps({
    incessions: Array,
    anesthesias: Array,
    contactProfileId: Number,
    editData: Object | null,
    onSumit: Function,
})

const emit = defineEmits(['onSumit']);

const store = useStore();
const route = useRoute();
const { formatPatientLabel } = useAsyncDropdownHelper()
const { fetchServiceList, storeOtOrder, updateOtOrder } = handleHospital()
const { fetchProducts } = handlePurchase();

const service_product = ref(null);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false);

const formData = reactive({
    company_id: null,
    ipd_register_id: null,
    contact_profile_id: null,
    product_id: null,
    incession: null,
    anestesia: null,
    operation_date: getCurrentDateTime(),
    indication: null,
    special_finding: null,
    service_resources: [],
});
const multipleFormValues = reactive({
    surgeon: [],
    anestesiologist: [],
    ass_surgeon: [],
    nurse: [],
});

const closeModal = () => {
    store.state.isModalOpen = false;
}

const submit = async () => {
    const formattedData = formatFormData();
    const id = props.editData ? props.editData.id : null;
    const otOrderAction = id ? updateOtOrder : storeOtOrder;

    loading.value = true

    await otOrderAction(formattedData, id).then((res) => {
        loading.value = false
        if (!res.status) return showError(res.message);
        closeModal();
        emit('onSumit');
        return showSuccess(res.message);
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const formatFormData = () => {
    const data = structuredClone(formData);
    data.company_id = route.params.companyId;
    data.ipd_register_id = route.query.admissionId;
    data.product_id = service_product.value ? service_product.value.id : null;

    for(let key in multipleFormValues){
        const otServices = multipleFormValues[key].map(item => {
            return {
                resource_id: item.id,
                type: key,
                id: item.pivot_id ? item.pivot_id : null,
            }
        });
        data['service_resources'] = data['service_resources'].concat(otServices);
    }

    return data;
}

const setEditData = (data) => {
    formData.ipd_register_id = data.ipd_register_id;
    formData.product_id = data.product_id;
    formData.incession = data.incession;
    formData.anestesia = data.anestesia;
    formData.operation_date = getCurrentDateTime(data.operation_date);
    formData.indication = data.indication;
    formData.special_finding = data.special_finding;

    for(let key in data.resources){
        multipleFormValues[key] = multipleFormValues[key].concat(data.resources[key]);
    }

    service_product.value = data.product;
}

onMounted(() => {
    if(props.editData){
        setEditData(props.editData)
    }
    formData.contact_profile_id = props.contactProfileId;
})

</script>


<style scoped>
.med-title{
    padding: 0.486rem 1.5rem;
}
.modal-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
</style>